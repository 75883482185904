/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'Header',
        import: () => import(/* webpackChunkName: "Header" */ '@stories/Widgets/Headers/Header/Header'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'SiteFooter',
        import: () => import(/* webpackChunkName: "SiteFooter" */ '@stories/Widgets/Footer/SiteFooter/SiteFooter'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ElfSight',
        import: () => import(/* webpackChunkName: "ElfSight" */ '@stories/Widgets/ElfSight/ElfSight'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HomePageHero',
        import: () => import('@stories/Widgets/HomePageHero/HomePageHero'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'ViewportBanner',
        import: () => import('@stories/Widgets/ViewportBanner/ViewportBanner'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'SignPostCards',
        import: () => import('@stories/Widgets/SignPostCards/SignPostCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HomePageHeroBanner',
        import: () => import('@stories/Widgets/HomePageHeroBanner/HomePageHeroBanner'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'SplitContent',
        import: () => import('@stories/Widgets/SplitContent/SplitContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LogosCarousel',
        import: () => import('@stories/Widgets/LogosCarousel/LogosCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PdpProductImage',
        import: () => import('@stories/Widgets/PdpProductImage/PdpProductImage'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PdpTopSection',
        import: () => import('@stories/Widgets/PdpTopSection/PdpTopSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'GalleryCarousel',
        import: () => import('@stories/Widgets/GalleryCarousel/GalleryCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CaseStudySignpostCarousel',
        import: () => import('@stories/Widgets/CaseStudySignpostCarousel/CaseStudySignpostCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CtaCards',
        import: () => import('@stories/Widgets/CtaCards/CtaCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ProductCarousel',
        import: () => import('@stories/Widgets/ProductCarousel/ProductCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SampleBag',
        import: () => import('@stories/Widgets/SampleBag/SampleBag'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SampleForm',
        import: () => import('@stories/Widgets/Forms/SampleForm/SampleForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InnerHeroBanner',
        import: () => import('@stories/Widgets/InnerHeroBanner/InnerHeroBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ProductListing',
        import: () => import('@stories/Widgets/ProductListing/ProductListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SearchResultsListing',
        import: () => import('@stories/Widgets/SearchResultsListing/SearchResultsListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Text',
        import: () => import('@stories/Widgets/Text/Text'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AssetImage',
        import: () => import('@stories/Widgets/AssetImage/AssetImage'),
        config: {
            hydrate: 'in-view',
        },
    },
];
